<template>
  <ValidationObserver>
    <form @submit.prevent="saveItem">
      <b-card title="Profile Mentor">
        <div class="text-center text-secondary my-2" v-if="isLoading">
          <b-spinner class="align-middle"></b-spinner>
          <strong>Loading...</strong>
        </div>
        <b-row v-else>
          <b-col cols="12" md="6">
            <div class="form-group">
              <label for="name">Name:</label>
              <validation-provider
                name="name"
                rules="required"
                v-slot="{ errors, classes }"
              >
                <input
                  id="name"
                  type="text"
                  class="form-control"
                  :class="classes"
                  :state="errors.length > 0 ? false : null"
                  v-model="formUser.name"
                  placeholder="Nama Users"
                />
                <small
                  v-for="(validation, index) in validations.name"
                  :key="`errorName${index}`"
                  class="text-danger"
                  >{{ validation }}</small
                >
              </validation-provider>
            </div>
            <div class="form-group">
              <label for="email">Email:</label>
              <validation-provider
                name="email"
                rules="required"
                v-slot="{ errors, classes }"
              >
                <input
                  id="email"
                  type="email"
                  class="form-control"
                  :class="classes"
                  :state="errors.length > 0 ? false : null"
                  v-model="formUser.email"
                  placeholder="budi@sisternet.com"
                />
                <small
                  v-for="(validation, index) in validations.email"
                  :key="`errorName${index}`"
                  class="text-danger"
                  >{{ validation }}</small
                >
              </validation-provider>
            </div>
            <div class="form-group">
              <label for="profession">Profession:</label>
              <input
                type="text"
                class="form-control"
                v-model="formUser.biodata.profession"
              />
            </div>
          </b-col>
          <input type="hidden" v-model="groupList" />
          <b-col cols="12" md="6">
            <div class="form-group">
              <label for="phone">Phone Number:</label>
              <validation-provider
                name="phone"
                rules="required"
                v-slot="{ errors, classes }"
              >
                <input
                  id="phone"
                  type="number"
                  class="form-control"
                  :class="classes"
                  :state="errors.length > 0 ? false : null"
                  v-model="formUser.phone_number"
                  placeholder="Nomor Telepon"
                />
                <small
                  v-for="(validation, index) in validations.phone_number"
                  :key="`errorName${index}`"
                  class="text-danger"
                  >{{ validation }}</small
                >
              </validation-provider>
            </div>
            <div class="form-group">
              <label for="password">Password:</label>
              <validation-provider
                name="phone"
                rules="required"
                v-slot="{ errors, classes }"
              >
                <input
                  id="password"
                  type="text"
                  class="form-control"
                  :class="classes"
                  :state="errors.length > 0 ? false : null"
                  v-model="formUser.password"
                  placeholder="Password"
                />
                <small
                  v-for="(validation, index) in validations.password"
                  :key="`errorName${index}`"
                  class="text-danger"
                  >{{ validation }}</small
                >
              </validation-provider>
            </div>
            <div class="form-group">
              <label for="avatar">Avatar:</label>
              <b-form-file
                id="avatar"
                placeholder="Choose a file or drop it here..."
                drop-placeholder="Drop file here..."
                ref="fileInput"
                accept="image/png, image/jpeg,"
                @change="handleFileChange($event, 'avatar')"
              />
            </div>
            <div class="text-center w-100">
              <img
                :src="image_avatar"
                class="rounded-circle"
                style="height: 200px; width: 200px; object-fit: cover"
                alt=""
                v-if="image_avatar !== null"
              />
            </div>
            <!-- <div class="form-group">
                <label for="instagram">Instagram Bussiness Account:</label>
                <input
                  id="instagram"
                  type="text"
                  class="form-control"
                  v-model="formUser.biodata.instagram_business_account"
                  placeholder="Nama Akun Bisnis Instagram"
                />
              </div> -->
            <!-- <div class="form-group">
                <label for="type">Activate Account:</label>
                <validation-provider name="type" rules="required">
                  <div class="demo-inline-spacing" style="margin-top: 8px">
                    <div class="custom-control custom-radio mt-0">
                      <input
                        name="headlineOptions"
                        class="custom-control-input"
                        type="radio"
                        id="radio1"
                        :value="'true'"
                        v-model="formUser.is_active"
                      />
                      <label class="custom-control-label" for="radio1">
                        Active
                      </label>
                    </div>
                    <div class="custom-control custom-radio mt-0">
                      <input
                        name="headlineOptions"
                        class="custom-control-input"
                        type="radio"
                        id="radio2"
                        :value="'false'"
                        v-model="formUser.is_active"
                      />
                      <label class="custom-control-label" for="radio2">
                        Not Active
                      </label>
                    </div>
                  </div>
                  <small
                    v-for="(validation, index) in validations.is_active"
                    :key="`errorName${index}`"
                    class="text-danger"
                    >{{ validation }}</small
                  >
                </validation-provider>
              </div> -->
          </b-col>
        </b-row>
      </b-card>
      <b-card title="Informasi User">
        <div class="text-center text-secondary my-2" v-if="isLoading">
          <b-spinner class="align-middle"></b-spinner>
          <strong>Loading...</strong>
        </div>
        <b-row v-else>
          <b-col cols="12" md="6" class="mb-2">
            <div class="form-group">
              <label for="about">About:</label>
              <validation-provider
                name="about"
                rules="required"
                v-slot="{ errors, classes }"
              >
                <textarea
                  id="about"
                  rows="5"
                  class="form-control"
                  maxlength="500"
                  :class="classes"
                  :state="errors.length > 0 ? false : null"
                  v-model="inputAbout"
                ></textarea>
              </validation-provider>
            </div>
          </b-col>
          <b-col cols="12" md="6" class="mb-2">
            <div
              class="form-row align-items-end"
              v-for="(social, index) in inputSocial"
              :key="index"
            >
              <div class="form-group col-md-5">
                <label for="">Sosial Media:</label>
                <input
                  type="text"
                  class="form-control"
                  v-model="inputSocial[index].platform"
                />
                <small
                  v-for="(validation, index) in validations[
                    'user_information.' + 'social_media.' + index + '.platform'
                  ]"
                  :key="`errorName${index}`"
                  class="text-danger"
                  >{{ validation }}</small
                >
              </div>
              <div class="form-group col-md-5">
                <label for="">URL:</label>
                <input
                  type="text"
                  class="form-control"
                  v-model="inputSocial[index].url"
                />
                <small
                  v-for="(validation, index) in validations[
                    'user_information.' + 'social_media.' + index + '.url'
                  ]"
                  :key="`errorName${index}`"
                  class="text-danger"
                  >{{ validation }}</small
                >
              </div>
              <div class="form-group col-md-2">
                <b-button
                  variant="danger"
                  class="btn waves-effect waves-float waves-light btn-danger"
                  @click="removeItem($event, index, 'social_media')"
                  v-show="index > 0"
                >
                  <trash-icon size="1x" class="custom-class"></trash-icon>
                </b-button>
              </div>
            </div>
            <b-button
              variant="primary"
              class="btn waves-effect waves-float waves-light btn-primary w-100"
              @click="addItem($event, 'social_media')"
              >+ Add Social Media</b-button
            >
          </b-col>
          <b-col cols="12" md="6">
            <div class="form-group">
              <label for="projects">Projects:</label>
              <div v-if="inputProjects.length === 0">
                <button
                  class="btn btn-primary"
                  @click="addItem($event, 'projects')"
                >
                  Add
                </button>
              </div>
              <template v-else>
                <div
                  class="input-group user-input-group mb-1"
                  v-for="(projects, index) in inputProjects"
                  :key="index"
                >
                  <input
                    :id="'projects' + index"
                    type="text"
                    class="form-control w-100"
                    v-model="inputProjects[index]"
                  />
                  <div class="input-group-append">
                    <button
                      class="btn btn-primary"
                      @click="
                        index === 0
                          ? addItem($event, 'projects')
                          : removeItem($event, index, 'projects')
                      "
                    >
                      {{ index === 0 ? "+" : "-" }}
                    </button>
                  </div>
                </div>
                <small
                  v-for="(validation, index) in validations.benefit"
                  :key="`errorName${index}`"
                  class="text-danger"
                  >{{ validation }}</small
                >
              </template>
            </div>
          </b-col>
          <b-col cols="12" md="6">
            <div class="form-group">
              <label for="awards">Awards:</label>
              <div v-if="inputAwards.length === 0">
                <button
                  class="btn btn-primary"
                  @click="addItem($event, 'awards')"
                >
                  Add
                </button>
              </div>
              <template v-else>
                <div
                  class="input-group user-input-group mb-1"
                  v-for="(awards, index) in inputAwards"
                  :key="index"
                >
                  <input
                    :id="'awards' + index"
                    type="text"
                    class="form-control w-100"
                    v-model="inputAwards[index]"
                  />
                  <div class="input-group-append">
                    <button
                      class="btn btn-primary"
                      @click="
                        index === 0
                          ? addItem($event, 'awards')
                          : removeItem($event, index, 'awards')
                      "
                    >
                      {{ index === 0 ? "+" : "-" }}
                    </button>
                  </div>
                </div>
                <!-- <small
                    v-for="(validation, index) in validations.benefit"
                    :key="`errorName${index}`"
                    class="text-danger"
                    >{{ validation }}</small
                  > -->
              </template>
            </div>
          </b-col>
        </b-row>
      </b-card>
      <b-card title="Galleries">
        <div class="text-center text-secondary my-2" v-if="isLoading">
          <b-spinner class="align-middle"></b-spinner>
          <strong>Loading...</strong>
        </div>
        <template v-else>
          <b-button
            variant="primary"
            class="btn waves-effect waves-float waves-light btn-primary"
            @click="addItem($event, 'gallery')"
          >
            Add Images
          </b-button>

          <b-row class="mt-2">
            <b-col
              cols="12"
              lg="4"
              v-for="(images, index) in inputGallery"
              :key="index"
            >
              <div class="form-group">
                <div
                  class="d-flex justify-content-between align-items-center mb-1"
                >
                  <label :for="'image' + index + 1" class="mb-0"
                    >Image {{ index + 1 }}</label
                  >
                  <b-button
                    variant="danger"
                    class="btn waves-effect waves-float waves-light btn-danger button__delete"
                    @click="removeItem($event, index, 'gallery')"
                    v-show="index > 0"
                  >
                    <trash-icon size="1x" class="custom-class"></trash-icon>
                  </b-button>
                </div>
                <b-form-file
                  :id="'image' + index + 1"
                  placeholder="Choose a file or drop it here..."
                  drop-placeholder="Drop file here..."
                  ref="fileInput"
                  accept="image/png, image/jpeg,"
                  @change="handleFileChange($event, 'galleries', index)"
                />
                <!-- <small
                  v-for="(validation, index) in validationsForm[
                    'images.' + index + '.file'
                  ]"
                  :key="`errorName${index}`"
                  class="text-danger"
                  >{{ validation }}</small
                > -->
                <div v-if="inputGallery[index].preview" class="mt-1">
                  <a :href="inputGallery[index].preview" target="_blank">
                    <img
                      :src="inputGallery[index].preview"
                      class="w-100 product__image rounded"
                    />
                  </a>
                </div>
              </div>
              <input type="hidden" v-model="images.position" />
            </b-col>
          </b-row>
        </template>
      </b-card>
      <b-col cols="12" class="p-0 mt-2">
        <button
          variant="primary"
          class="btn waves-effect waves-float waves-light btn-primary"
          :disabled="isLoading"
        >
          {{ isLoading ? "Submitting..." : "Submit" }}
        </button>
        &nbsp; &nbsp;
        <a
          @click="handleBackButton()"
          variant="secondary"
          class="btn waves-effect waves-float waves-light btn-secondary"
        >
          Cancel
        </a>
      </b-col>
    </form>
  </ValidationObserver>
</template>

<script>
import vSelect from "vue-select";
import { TrashIcon } from "vue-feather-icons";
import {
  BCard,
  BFormGroup,
  BFormRadio,
  BRow,
  BCol,
  BFormCheckbox,
  BButton,
  BFormFile,
  BSpinner,
} from "bootstrap-vue";
import {
  ValidationProvider,
  ValidationObserver,
  configure,
} from "vee-validate";
import { required } from "@validations";
import { successNotification, errorNotification } from "@/auth/utils";
configure({
  classes: {
    valid: "is-valid",
    invalid: "is-invalid",
    dirty: ["is-dirty", "is-dirty"], // multiple classes per flag!
    // ...
  },
});
export default {
  components: {
    BCard,
    BRow,
    BCol,
    BFormGroup,
    BFormRadio,
    BFormCheckbox,
    vSelect,
    TrashIcon,
    BButton,
    BFormFile,
    BSpinner,
    ValidationProvider,
    ValidationObserver,
  },

  setup() {
    return {
      successNotification,
      errorNotification,
    };
  },

  data() {
    return {
      required,
      isLoading: false,
      formUser: {
        name: "",
        email: "",
        is_active: "",
        phone_number: "",
        password: "",
        groups: [],
        avatar: null,
        biodata: {
          profession: "",
          instagram_business_account: "",
          company: "",
        },
        user_information: [
          {
            about: "",
            gallery: [],
            projects: [],
            awards: [],
            social_media: [],
          },
        ],
      },
      inputAbout: "",
      image_avatar: null,
      groupList: [],
      groupUser: [],
      validations: "",
      inputProjects: [],
      inputAwards: [],
      inputSocial: [],
      inputGallery: [],
      // Must be an array reference!
    };
  },

  created() {
    this.loadUser();
  },

  methods: {
    handleBackButton() {
      history.back();
    },
    addItem(event, type) {
      event.preventDefault();
      if (type === "projects" && this.inputProjects) {
        this.inputProjects.push("");
      } else if (type === "awards" && this.inputAwards) {
        this.inputAwards.push("");
      } else if (type === "gallery" && this.inputGallery) {
        this.inputGallery.push({
          position: this.inputGallery.length,
          file: null,
          preview: null,
        });
      } else {
        this.inputSocial.push({
          platform: "",
          url: "",
        });
      }
    },
    removeItem(event, index, type) {
      event.preventDefault();
      if (this.formUser.user_information) {
        if (type === "projects" && this.inputProjects) {
          this.inputProjects.splice(index, 1);
        } else if (type === "awards" && this.inputAwards) {
          this.inputAwards.splice(index, 1);
        } else if (type === "gallery" && this.inputGallery) {
          this.inputGallery.splice(index, 1);
          this.updatePositions();
        } else {
          this.inputSocial.splice(index, 1);
        }
      }
    },
    updatePositions() {
      this.inputGallery.forEach((gallery, index) => {
        gallery.position = index;
      });
    },
    handleFileChange(event, type, index) {
      const file = event.target.files[0];
      if (type === "galleries") {
        this.inputGallery[index].file = file;
        this.inputGallery[index].preview = URL.createObjectURL(file);
      } else {
        this.formUser.avatar = file;
        this.image_avatar = URL.createObjectURL(file);
      }
    },
    loadUser() {
      this.isLoading = true;
      this.$http.get("/api/v1/mentor/mentorship/profile").then((response) => {
        this.formUser = response.data.data;
        this.image_avatar = this.formUser.avatar;
        this.groupList = this.formUser.groups;
        this.formUser.is_active = this.formUser.is_active.toString();
        this.inputAbout = this.formUser.user_information
          ? this.formUser.user_information.about
          : "";
        this.inputSocial = this.formUser.user_information
          ? this.formUser.user_information.social_media
          : [];
        this.inputProjects = this.formUser.user_information
          ? this.formUser.user_information.projects
          : [];
        this.inputAwards = this.formUser.user_information
          ? this.formUser.user_information.awards
          : [];
        let newImages = [];
        for (
          let index = 0;
          index < this.formUser.user_information.gallery.length;
          index++
        ) {
          const imagesItem = this.formUser.user_information.gallery[index];
          const imagesData = {
            file: imagesItem,
            position: index,
            preview: imagesItem,
          };
          newImages.push(imagesData);
        }
        this.formUser.user_information.gallery = newImages;
        this.inputGallery = this.formUser.user_information
          ? this.formUser.user_information.gallery
          : [];
        this.isLoading = false;
      });
    },
    saveItem() {
      this.isLoading = true;
      let groupId = [];
      this.groupList.forEach((group) => groupId.push(group.id));
      this.formUser.groups = groupId;
      this.formUser.user_information.about = this.about;
      this.formUser.user_information.projects = this.inputProjects;
      this.formUser.user_information.gallery = this.inputGallery;
      this.formUser.user_information.awards = this.inputAwards;
      this.formUser.user_information.social_media = this.inputSocial;
      const payload = new FormData();
      payload.append("name", this.formUser.name);
      payload.append("email", this.formUser.email);
      payload.append("is_active", this.formUser.is_active);
      payload.append("phone_number", this.formUser.phone_number);
      payload.append(
        "user_information[about]",
        this.formUser.user_information.about
      );
      for (let index = 0; index < this.groupList.length; index++) {
        const groupUuid = this.groupList[index]["id"];
        payload.append("groups[]", groupUuid);
      }
      if (this.formUser.password) {
        payload.append("password", this.formUser.password);
      }
      const { profession, instagram_business_account, company, about } =
        this.formUser.biodata;
      if (profession) {
        payload.append("other[profession]", profession);
      }
      if (instagram_business_account) {
        payload.append(
          "other[instagram_business_account]",
          instagram_business_account
        );
      }
      if (company) {
        payload.append("other[company]", company);
      }

      if (this.formUser.user_information?.projects.length > 0) {
        for (let i = 0; i < this.inputProjects.length; i++) {
          payload.append(
            `user_information[projects][${i}]`,
            this.inputProjects[i]
          );
        }
      }
      if (this.formUser.user_information?.awards.length > 0) {
        for (let i = 0; i < this.inputAwards.length; i++) {
          payload.append(`user_information[awards][${i}]`, this.inputAwards[i]);
        }
      }
      this.inputGallery.forEach((gallery, index) => {
        Object.keys(gallery).forEach((key) => {
          if (key !== "preview") {
            payload.append(
              "user_information[gallery]" + "[" + index + "][" + key + "]",
              gallery[key]
            );
          }
        });
      });
      this.inputSocial.forEach((social, index) => {
        Object.keys(social).forEach((key) => {
          payload.append(
            "user_information[social_media]" + "[" + index + "][" + key + "]",
            social[key]
          );
        });
      });
      this.$http
        .post("/api/v1/mentor/mentorship/update-profile", payload, {
          headers: { "Content-Type": "multipart/form-data" },
        })
        .then((response) => {
          this.isLoading = false;
          successNotification(this, "Success", "User sukses diedit!");
        })
        .catch((error) => {
          this.isLoading = false;
          if (error.response.data.meta.messages) {
            errorNotification(this, "Error", error.response.data.meta.messages);
          } else if (error.response.data.meta.validations) {
            this.validations = error.response.data.meta.validations;
            errorNotification(this, "Nama", this.validations.name.join(" "));
            errorNotification(this, "Email", this.validations.email.join(" "));
            errorNotification(
              this,
              "No Telepon",
              this.validations.phone_number.join(" ")
            );
            errorNotification(
              this,
              "Password",
              this.validations.password.join(" ")
            );
            errorNotification(
              this,
              "Groups",
              this.validations.groups.join(" ")
            );
          }
        });
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";

.user-input-group {
  flex-flow: row;
}

.user-input-group span {
  width: 100%;
}

.button__delete {
  padding: 2px !important;
}
.product__image {
  height: 300px;
  object-fit: cover;
}
</style>
